
import { createColumn, formatAsDate, formatAsCurrency } from '@/util/table';
import PaginationMixin from '@/mixins/PaginationMixin';
import { OrderStatus } from '@/repositories/OrderRepository';
import RequestOrder from '@/models/RequestOrder';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import CommonMixin from '@/mixins/CommonMixin';
import { FinalOrderModalMode } from '@/components/Tools/Order/OrderModalModes';

export default {
  mixins: [CommonMixin, PaginationMixin],
  data() {
    return {
      data: [],
      loading: true,
      appends: [
        createColumn('reviewDate', 'Reviewed Date', formatAsDate),
        createColumn(
          'grandTotal',
          'Request Grand Total',
          formatAsCurrency(row => row.currency.code)
        )
      ],
      showDialog: false,
      showPaymentDialog: false,
      mode: FinalOrderModalMode.VIEW,
      selectedOrder: {}
    };
  },
  async created() {
    await this.fetchOrders();
  },
  methods: {
    showDetailDialog(order: RequestOrder) {
      this.selectedOrder = order;
      this.showDialog = true;
    },
    showViewDialog(order: RequestOrder) {
      this.mode = FinalOrderModalMode.VIEW;
      this.showDetailDialog(order);
    },
    showProceedDialog(order: RequestOrder) {
      this.mode = FinalOrderModalMode.INPUT;
      this.showDetailDialog(order);
    },
    showPaymentConfirmationDialog(order: RequestOrder) {
      this.showPaymentDialog = true;
      this.selectedOrder = order;
    },
    async finalize(order: RequestOrder) {
      try {
        const updatedOrder = await this.$repo.order.finalizeOrder(order);
        this.data = this.data.filter(entry => entry.id !== order.id);
        this.showDialog = false;
        handleSuccessUI(
          `Order for ${order.store} has been successfully finalized`
        );
        this.showPaymentConfirmationDialog(updatedOrder);
      } catch (err) {
        handleErrorUI(err);
      }
    },
    async fetchOrders() {
      this.loading = true;
      try {
        const { tenant } = await this.$repo.session.fetch();
        const { data, pagination } = await this.$repo.order.getOrderWithStatus(
          tenant,
          OrderStatus.WAITING_FOR_FINAL_ORDER,
          this.pagination
        );
        this.data = data;
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchOrders();
    }
  }
};
